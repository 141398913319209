import React, {useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import useHeader from "../../hooks/ui/useHeader";

// image
import Logo from '../../assets/images/logo.png'

function Header() {
  
  const { links } = useHeader();
  const [sticky, setSticky] = useState(false);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false)

  const resize = window.innerWidth > 1023;

  const headerScroll = () => {
    if (window.scrollY === 0) {
      setSticky(false);
    } else  {
      setSticky(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', headerScroll);
    return () => window.removeEventListener('scroll', headerScroll);
  }, []);

  return (
    <header className={`${sticky && resize ? 'bg-white lg:py-8' : 'lg:py-14'} max-w-screen-2xl w-full m-auto fixed top-0 left-0 right-0 z-50 transition-all duration-300`}>
      <nav className={'main-menu'}>
        <ul className={'hidden lg:flex relative'}>
          {links.map((item, idx) => {
              const linkPath = item.fields.link;
              const activePath = pathname === linkPath || pathname === '/' + linkPath;

              return (
                <li className={`${idx === 0 ? 'xl:ml-24 lg:ml-10 md:ml-6 mx-6' : '2xl:mx-6 md:mx-4'}`} key={idx}>
                  <Link to={`${linkPath === '/' ? item.fields.link : '/' + item.fields.link}`} className={'group relative pb-2 font-sans font-medium uppercase text-tiny text-base-color '} >
                      {item.fields.linkName}
                    <span className={`${activePath ? 'w-opacity-100 bottom-0' : 'opacity-0 -bottom-2.5'} group-hover:opacity-100 group-hover:bottom-0 group-hover:duration-300 absolute left-0 h-1 bg-gray-900 w-full transition-all duration-500`} style={{ backgroundColor: `${item.fields.lineColor}`}}></span>
                  </Link>
                </li>
              )
          })}
          <li className={`absolute right-0 transition-all duration-500 ${sticky ? 'w-44 h-48 xl:top-0 md:top-12' : 'w-80 h-96 top-20'}`}>
            <img className={'w-full'} src={Logo} alt={'logo'} />
          </li>
        </ul>

        <div className="lg:hidden flex items-center text-base-color pt-12 pl-5 sm:pl-9" onClick={() => setIsOpen(!isOpen)}>
            <svg className="block h-7 sm:h-9 w-10 sm:w-12 fill-current -ml-1 sm:-ml-2" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </div>

        <div className={'lg:hidden absolute right-0 w-24 sm:w-44 h-24 sm:h-48 top-12 sm:top-14 z-10'}>
          <img className={'w-full'} src={Logo} alt={'logo'} />
        </div>
      </nav>

      <nav className={`lg:hidden burger-menu ${isOpen ? 'transform translate-x-0' : 'transform -translate-x-full '} duration-300 fixed w-full top-0 left-0 bottom-0 flex flex-col py-11 pl-9 bg-white`}>
        <div className={'flex items-center mb-24 sm:mb-48'} onClick={() => setIsOpen(!isOpen)}>
          <svg className={'-ml-2 w-9 sm:w-12 h-9 sm:h-12'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M9.156 6.313 6.312 9.155 22.157 25 6.22 40.969 9.03 43.78 25 27.844 40.938 43.78l2.843-2.843L27.844 25 43.687 9.156l-2.843-2.844L25 22.157Z" /></svg>
        </div>

        <div className={''}>
          <ul className={'leading-snug'}>
            {links.map((item, idx) => {
              return (
                <li className={'mb-4 sm:mb-10'} key={idx}>
                    <Link to={`${item.fields.link}`} onClick={() => setIsOpen(!isOpen)} className={'text-2xl sm:text-5xl font-medium text-base-color '}>
                    {item.fields.linkName}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;
