import useContentful from '../api/useContentful';

export default function useHeader() {
    const { data, isLoading } = useContentful('bVg3e25KfmsUFBLC9ZiqB');
    const links = data?.links || [];
    
    return {
        links,
        isLoading
    };
}
