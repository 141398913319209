import useContentful from '../api/useContentful';

export default function useOgData() {
    const { data, isLoading } = useContentful('pCdclFcvnJIQj2JDNY7Fh');
    const ogTitle  = data?.ogTitle || '';
    const ogImage  = data?.ogImage?.fields?.file || '';
    const ogDescription  = data?.ogDescription || '';
    
    return {
        ogTitle,
        ogImage,
        ogDescription,
        isLoading
    };
}
