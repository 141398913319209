import React from 'react';
import FooterImage from '../../assets/images/footer.png'

function Footer() {
  return (
    <footer>
      <img src={FooterImage} alt={'We all rise together'} />
    </footer>
  );
}

export default Footer;
