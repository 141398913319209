import client from './client';

export default async function fetcherContentTypes(key) {
	const response = await client
		.getEntries({
		content_type: key,
		include: 10,
	})
	return response.items;
}
