import React, { Suspense, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Components
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Modal from './components/modal';
import ConnectForm from './components/connect-form';
import NotFound from "./components/not-found";

// Utils
import ScrollToTop from "./utils/scrollToTop";
import useOgData from "./hooks/ui/useOgData";

const HomeComponent = React.lazy(() => import('./pages/home'));
const MobilityComponent = React.lazy(() => import('./pages/mobility'));
const WealthComponent = React.lazy(() => import('./pages/wealth'));
const HealthWellnessComponent = React.lazy(() => import('./pages/health-wellness'));
const WomanToWomanComponent = React.lazy(() => import('./pages/woman-to-woman'));
const ResourcesComponent = React.lazy(() => import('./pages/resources'));
const PressEventsComponent = React.lazy(() => import('./pages/press-events'));
const EventDetailsComponent = React.lazy(() => import('./pages/event-details'));
const AboutComponent = React.lazy(() => import('./pages/about'));
const DynamicComponent = React.lazy(() => import('./pages/dynamic'));

function App() {
    const { ogTitle, ogImage, ogDescription, } = useOgData();
    const [showModal, setShowModal] = useState(false);
  
    return (
          <Suspense fallback={<span>Loading...</span>}>
                <Router>
                  <ScrollToTop>
                    <Helmet>
                      <title>{ogTitle}</title>
                      <meta property="og:title" content={ogTitle} />
                      <meta property="og:type" content="website" />
                      <meta property="og:description" content={ogDescription} />
                      <meta property="og:image" content={ogImage.url} />
                    </Helmet>
                    <div className={'app-container max-w-screen-2xl m-auto'}>
                      <Header />
                      
                      <Switch>
                        <Route exact path='/' component={HomeComponent} />

                        <Route path='/health-wellness' component={HealthWellnessComponent} />

                        <Route path='/mobility' component={MobilityComponent} />

                        <Route path='/wealth' component={WealthComponent} />
                        
                        <Route path='/about' component={AboutComponent} />

                        <Route path='/woman-to-woman' component={WomanToWomanComponent} />

                        <Route path='/press-events/:slug' component={EventDetailsComponent} />

                        <Route path='/press-events' component={PressEventsComponent} />

                        <Route path='/resources' component={ResourcesComponent} />
                        
                        <Route path='/:slug' component={DynamicComponent} />
                        
                        <Route path='*' component={NotFound} />
                        
                      </Switch>

                      <Footer />

                        <Modal showModal={showModal}>
                            <ConnectForm setShowModal={setShowModal} />
                        </Modal>
                    </div>
                    </ScrollToTop>
                </Router>
          </Suspense>
    );
}

export default App;
