import React from 'react';

function ConnectForm({setShowModal}) {
    return (
        <div className={'bg-gloomy rounded-md w-310px xs:w-340px ls:w-420px md:w-500px h-415px xs:h-450px ls:h-520px md:h-615px overflow-hidden'}>
          <div className={'flex w-full h-450px xs:h-450px ls:h-520px md:h-615px overflow-y-scroll'}>
            <div className={'w-full h-1075px xs:h-1030px sm:h-965px md:h-965px lg:h-1075px'}>
              <iframe
                width={'100%'}
                height={'100%'}
                className={'w-full h-full'}
                loading='eager'
                title="subscribe form"
                src={'https://weallrisetogether.us1.list-manage.com/subscribe/post?u=17e3114aad8105e8050c80507&amp;id=d804525bd9'}
                scrolling="no" />
            </div>
          </div>
          <button className={'join-form_btn absolute top-0 right-1 lg:right-7 z-75 font-semibold xs:text-4xl text-3xl cursor-pointer'} onClick={() => setShowModal(false)}>×</button>
      </div>
    )
}

export default ConnectForm;
