import useSWR, { cache } from 'swr';
import fetcher from '../../contentful/fetcher';
import fetcherContentTypes from '../../contentful/fetcherContentTypes';

export default function useContentful(key, type) {
    const { data, error } = useSWR(key, type ? fetcherContentTypes : fetcher, {
        revalidateOnMount: !cache.has(key)
    });

    const isLoading = !data && !error;

    return {
        isLoading,
        data,
        error
    };
}
