import React from 'react';

function Modal({ children, showModal }) {
	return (
		<>
			{showModal ?
				<div className={'bg-black bg-opacity-80 fixed top-0 left-0 right-0 bottom-0 z-50'}>
					<div className={'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-gloomy rounded-md'}>
	            {children}
	        </div>
				</div>
				:
				null
			}
    </>
	)
}

export default Modal;
